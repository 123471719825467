/* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
					   Sashidhar Boyapati (1002087319)
					   Hema Sai Desai (1002029973)
					   Hemanth Reddy Gillela (1002033611)
					   Smrithi Gujjula (1002073701)  
                       
*/

.container .row .sidebar .home-icon {
    margin-left: 5px;
    height: 80px;

}
.container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.row {
    flex: 1;
    display: flex;
    top: 0;
    bottom: 0;
}

.sidebar {
    flex: 1;
    background-color: rgb(255, 255, 255);
    top: 0;
    left: 0;
    height: 90vh;
    padding: 20px 0;
    transition: all 0.5s ease;
    border-width:0.2px;
    border-style:solid;
    border-color:rgb(252, 213, 213);
}
.sidebar .logo img{
    display: block;
    height: 107px;
    margin: 0 auto;
}
.sidebar ul li a{
    display: block;
    padding: 13px 15px;
    text-decoration: none;
    border-bottom: 1px solid #ffcece;
    color: rgb(0, 0, 0);
    font-size: 16px;
    position: relative;
}
.sidebar ul li a:hover {
    background-color: #c0bfc2;
    color: #000000;
}
.content {
    flex: 3;
    padding: 77px 111px;
    color: black;
    align-items: flex-end;
}
.chat-container {
    width: 400px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.chat-header {
    background-color: #0074D9;
    color: white;
    padding: 10px;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.message-container {
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
}

.message {
    max-width: 50%;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
}

.message.admin {
    background-color: #0074D9;
    align-self: flex-start;
    color: white;
}

.message.student {
    background-color: white;
    align-self: flex-end;
    color: #333;
}

.input-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ccc;
}

.message-input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    resize: none;
}

.send-button {
    background-color: #0074D9;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
}

.send-button:hover {
    background-color: #0056a0;
}

.footer {
    background-color: #291749;
    color: #fff;
    font-size: 10px;
    text-align: center;
    padding: 10px 0;
    display: block;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
}


