/* Add your styles for the chat container */
/* Add this to your CSS file */

.chat-container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 300px;
    height: 400px;
    border: 1px solid #ccc;
    overflow: hidden;
  }
  
  .chat-header {
    background-color: #3498db;
    color: #fff;
    padding: 10px;
    text-align: center;
  }
  
  .chat-header h6 {
    margin: 0;
    font-size: 16px;
  }
  
  .close-button {
    background-color: #e74c3c;
    color: #fff;
    border: none;
    padding: 5px; 
    cursor: pointer;
    font-size: 12px; /* Adjust the font size */
    border-radius: 5px; /* Add border-radius for a curved effect */
  }
  
  .close-button:hover {
    background-color: #c0392b;
  }
.chat-container {
    display: flex;
    flex-direction: column;
    width: 300px; /* Adjust the width as needed */
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
  }
  
  /* Styles for the chat messages */
  .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }
  
  /* Styles for each individual message */
  .user-message,
  .other-message {
    margin: 5px 0;
    padding: 8px;
    border-radius: 8px;
  }
  
  .user-message {
    background-color: #4caf50; /* Green for user's messages */
    color: white;
    align-self: flex-end;
  }
  
  .other-message {
    background-color: #f1f1f1; /* Light gray for others' messages */
    color: #333;
    align-self: flex-start;
  }
  
  /* Styles for the input and send button */
  .chat-input {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 8px;
  }
  
  button {
    padding: 8px 16px;
    background-color: #2196f3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0b7dda;
  }
  