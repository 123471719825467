

/* Chatbot container */
.react-simple-chatbot-container {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
  }
  
  /* Chatbot header */
  .react-simple-chatbot-header {
    background-color: #4caf50; /* Header background color */
    color: #fff; /* Header text color */
    padding: 10px;
    border-radius: 5px 5px 0 0;
  }
  
  /* Chatbot messages container */
  .react-simple-chatbot-messages {
    max-height: 300px; /* Adjust as needed */
    overflow-y: auto;
    padding: 10px;
  }
  
  /* User message */
  .user-message {
    background-color: #2196f3; /* User message background color */
    color: #fff; /* User message text color */
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 8px;
  }
  
  /* Bot message */
  .bot-message {
    background-color: #e0e0e0; /* Bot message background color */
    color: #000; /* Bot message text color */
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 8px;
  }
  
  /* Chatbot input container */
  .react-simple-chatbot-input-container {
    border-top: 1px solid #ccc;
    padding: 10px;
    border-radius: 0 0 5px 5px;
  }
  
  /* Chatbot input */
  .react-simple-chatbot-input {
    width: calc(100% - 20px);
    padding: 8px;
    border: none;
    border-radius: 5px;
  }
  
  /* Chatbot input submit button */
  .react-simple-chatbot-button {
    background-color: #4caf50; /* Submit button background color */
    color: #fff; /* Submit button text color */
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Hover effect on submit button */
  .react-simple-chatbot-button:hover {
    background-color: #45a049; /* Hover background color */
  }
  